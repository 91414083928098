import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { compose } from 'recompose';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Scroll from 'react-scroll';

import { SECTIONS } from 'modules/intake/constants';
import { isCandidateMoveType, isInternMoveType } from 'modules/intake/models/authorizationInformation';
import Log from 'utilities/log';
import StepLabel from 'modules/common/stepLabel.component';

import BenefitsTimelineCalendar from './benefitsTimelineCalendar.component';
import BenefitsTimelineDates from './benefitsTimelineDates.component';
import CandidateInternForm from './candidateInternForm.component';
import FinalMoveForm from './finalMoveForm.component';
import HomesaleProcessForm from './homesaleProcessForm.component';
import HouseHuntingForm from './houseHuntingForm.component';
import HouseholdGoodsForm from './householdGoodsForm.component';
import MiscellaneousForm from './miscellaneousForm.component';
import RelocationAllowanceForm from './relocationAllowanceForm.component';
import SpousalAssistanceForm from './spousalAssistanceForm.component';
import TemporaryLivingForm from './temporaryLivingForm.component';

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    marginTop: theme.spacing(2),
  },
  formContainer: {
  },
  fixedContainer: {
    position: 'fixed',
    right: theme.spacing(2),
  },
  datesContainer: {
    overflowY: 'scroll',
    bottom: 0,
    padding: theme.spacing(1),
  },
  timelineContainer: {
    padding: theme.spacing(1),
    overflowY: 'scroll',
    maxWidth: 324, // 16px for padding, 308 for calendar
  },
  instructions: {
    paddingTop: '0 !important',
    paddingLeft: '32px !important',
  },
  section: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    paddingTop: '0 !important',
    paddingLeft: '32px !important',
    position: 'relative',
    '&:last-child': {
      borderLeft: 0,
    },
  },
  startDateContainer: {
    alignSelf: 'flex-end',
  },
});

const alwaysFalse = () => false;
const ROOT_PATH = 'benefits';

class BenefitsCounselingForm extends PureComponent {
  constructor(props) {
    super(props);
    this.relocationAllowance = React.createRef();
    this.candidateInternInfo = React.createRef();
    this.houseHunting = React.createRef();
    this.homesaleProcess = React.createRef();
    this.finalMove = React.createRef();
    this.temporaryLivingTimeline = React.createRef();
    this.householdGoods = React.createRef();
    this.spousalAssistance = React.createRef();
    this.miscellaneous = React.createRef();
    this.fixedContainer = React.createRef();
    this.mainContainer = React.createRef();
    this.state = {
      fixedContainerTop: '27%',
      sections: [{
        render: this.renderRelocationAllowanceForm,
        isDisabled: alwaysFalse,
      }, {
        render: this.renderCandidateInternForm,
        isDisabled: alwaysFalse,
        serviceSectionGroupName: 'authorizationInformation',
      }, {
        render: this.renderHouseHuntingForm,
        isDisabled: alwaysFalse,
        serviceSectionGroupName: 'newDestination',
      }, {
        render: this.renderHomesaleProcessForm,
        isDisabled: alwaysFalse,
        serviceSectionGroupName: 'departureProperty',
      }, {
        render: this.renderFinalMoveForm,
        isDisabled: alwaysFalse,
        serviceSectionGroupName: 'newDestination',
      }, {
        render: this.renderTemporaryLivingForm,
        isDisabled: alwaysFalse,
        serviceSectionGroupName: 'temporaryLiving',
      }, {
        render: this.renderHouseholdGoodsForm,
        isDisabled: alwaysFalse,
        serviceSectionGroupName: 'hhgInformation',
      }, {
        render: this.renderSpousalAssistanceForm,
        isDisabled: alwaysFalse,
      }, {
        render: this.renderMiscellaneousForm,
        isDisabled: alwaysFalse,
      }],
      height: window.innerHeight,
    };
  }

  componentWillMount(){
    this.setState({ height: window.innerHeight });
  }

  componentDidMount() {
    const { focusedGroup } = this.props;
    if (focusedGroup && this[focusedGroup]) {
      setTimeout(() => {
        if (this[focusedGroup].current) {
          Scroll.scroller.scrollTo(this[focusedGroup].current, { offset: -200, duration: 1000, align: 'top' });
          this.props.clearFocusedGroup();
        }
      });
    }

    this.setState({
      fixedContainerTop: this.mainContainer.current.offsetTop,
    });
  }

  renderRelocationAllowanceForm = (values, formProps, onEnableService) => {
    return (
      <RelocationAllowanceForm
        ref={this.relocationAllowance}
        relocationAllowance={values.benefits.relocationAllowance}
        rootPath={ROOT_PATH}
        {...formProps}
      />
    );
  };

  renderCandidateInternForm = (values, formProps, onEnableService, metadata) => {
    return (
      <CandidateInternForm
        ref={this.candidateInternInfo}
        candidateIntern={values.benefits.candidateIntern}
        isIntern={isInternMoveType(values.authorizationInformation.moveTypeId, metadata.moveTypes)}
        isFormEnabled={isCandidateMoveType(values.authorizationInformation.moveTypeId, metadata.moveTypes) ||
          isInternMoveType(values.authorizationInformation.moveTypeId, metadata.moveTypes)}
        onChangeMoveType={onEnableService}
        rootPath={ROOT_PATH}
        {...formProps}
      />
    );
  };

  renderHouseHuntingForm = (values, formProps, onEnableService ) => {
    return (
      <HouseHuntingForm
        ref={this.houseHunting}
        houseHunting={values.benefits.houseHunting}
        rootPath={ROOT_PATH}
        isFormEnabled={values.newDestination.isDestinationServiceInitiated}
        onEnableService={onEnableService}
        {...formProps}
      />
    );
  };

  renderHomesaleProcessForm = (values, formProps, onEnableService, metadata) => {
    return (
      <HomesaleProcessForm
        ref={this.homesaleProcess}
        homesaleProcess={values.benefits.homesaleProcess}
        homesaleType={metadata.homesaleTypes[values.departureProperty.homesaleTypeId]}
        rootPath={ROOT_PATH}
        isFormEnabled={!!values.departureProperty.isSellingDepartureProperty}
        onEnableService={onEnableService}
        {...formProps}
      />
    );
  };

  renderFinalMoveForm = (values, formProps, onEnableService) => {
    return (
      <FinalMoveForm
        ref={this.finalMove}
        finalMove={values.benefits.finalMove}
        rootPath={ROOT_PATH}
        isFormEnabled={values.newDestination.isDestinationServiceInitiated}
        onEnableService={onEnableService}
        {...formProps}
      />
    );
  };

  renderTemporaryLivingForm = (values, formProps, onEnableService) => {
    return (
      <TemporaryLivingForm
        ref={this.temporaryLivingTimeline}
        temporaryLiving={values.benefits.temporaryLiving}
        isFormEnabled={values.temporaryLiving.isTemporaryLivingServiceInitiated}
        onEnableService={onEnableService}
        rootPath={ROOT_PATH}
        {...formProps}
      />
    );
  };

  renderHouseholdGoodsForm = (values, formProps, onEnableService, metadata) => {
    const isCandidateOrIntern = isCandidateMoveType(values.authorizationInformation.moveTypeId, metadata.moveTypes) ||
      isInternMoveType(values.authorizationInformation.moveTypeId, metadata.moveTypes);
    
    return (
      <HouseholdGoodsForm
        ref={this.householdGoods}
        householdGoods={values.benefits.householdGoods}
        temporaryLiving={values.temporaryLiving}
        isFormEnabled={!isCandidateOrIntern}
        onEnableService={onEnableService}
        onChangeMoveType={() => { this.props.onSectionFocus(SECTIONS.find((navSection) => navSection.group === 'authorizationInformation')); }}
        isServiceEnabled={values.hhgInformation.isHhgServiceInitiated}
        rootPath={ROOT_PATH}
        {...formProps}
      />
    );
  };

  renderSpousalAssistanceForm = (values, formProps, onEnableService) => {
    return (
      <SpousalAssistanceForm
        ref={this.spousalAssistance}
        spousalAssistance={values.benefits.spousalAssistance}
        rootPath={ROOT_PATH}
        {...formProps}
      />
    );
  };

  renderMiscellaneousForm = (values, formProps, onEnableService) => {
    return (
      <MiscellaneousForm
        ref={this.miscellaneous}
        miscellaneous={values.benefits.miscellaneous}
        rootPath={ROOT_PATH}
        {...formProps}
      />
    );
  };

  render() {
    Log.trace('RENDER', 'BenefitsCounselingForm');
    const { classes, values, ...rest } = this.props;
    const { metadata } = this.props;
    const {
      primaryInformation: { newJobStartDate },
      authorizationInformation: { authorizationDate },
    } = values;
    const { sections } = this.state;

    return (
      <Grid container classes={{ container: classes.container }} ref={this.mainContainer}>
        <Grid container item xs={6} classes={{ container: classes.formContainer }}>
          <Grid item xs={12} classes={{ item: classes.instructions }}>
            <Typography>Is the EE following along with their policy?</Typography>
            <Typography gutterBottom>Do they have questions prior to reviewing benefits?</Typography>
          </Grid>
          {
            sections.filter((section) => !section.isDisabled(values, metadata)).map((section, i) => 
            {
              let onEnableService = () => { this.props.onSectionFocus(SECTIONS.find((navSection) => navSection.group === section.serviceSectionGroupName)); };
              return (
                <Grid key={section.render} item xs={12} classes={{ item: classes.section }}>
                  <StepLabel active={i === 0}>{i + 1}</StepLabel>
                  {section.render(values, rest, onEnableService, metadata)}
                </Grid>);
            })
          }
        </Grid>
        <Grid container item xs={5} classes={{ item: classes.fixedContainer }} ref={this.fixedContainer} style={{ top: this.state.fixedContainerTop }}>
          <Grid item xs={5}>
            <Paper className={classes.datesContainer} style={{ height: this.state.height * .66 }}>
              <BenefitsTimelineDates values={values} />
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Paper className={classes.timelineContainer} style={{ maxHeight: this.state.height*.66 }}>
              <BenefitsTimelineCalendar
                authorizationDate={authorizationDate}
                newJobStartDate={newJobStartDate}
                benefits={values.benefits}
                {...rest}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

BenefitsCounselingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  focusedGroup: PropTypes.string,
  clearFocusedGroup: PropTypes.func.isRequired,
  onSectionFocus: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
)(BenefitsCounselingForm);
