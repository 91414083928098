import { Button, Paper, Typography, withStyles } from '@material-ui/core';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import Log from 'utilities/log';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SECTIONS, ValidationMode } from 'modules/intake/constants';
import { hideToast, showToast } from 'modules/layout/layout.actions';
import { setValidationMode } from 'modules/intake/intake.actions';
import Spinner from 'modules/common/spinner.component';

import { ERROR } from './errorLevels';
import ValidationAlertCard from './validationAlertCard.component';

const styles = (theme) => ({
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  card: {
    padding: theme.spacing(2),
  },
  cardsContainer: {
    columnCount: 3,
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    },
  },
  cardContainer: {
    '-webkit-column-break-inside': 'avoid',
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    marginBottom: theme.spacing(2),
  },
});

class Summary extends PureComponent {
  state = {
    didSubmit: false,
  };

  componentDidMount() {
    Log.trace('Summary Mounted', 'Summary');

    this.props.setValidationMode(ValidationMode.Strict);
    setTimeout(async () => {
      await this.props.submitForm();
      this.setState({ didSubmit: true });
    });
  }

  render() {
    const { classes, isValidating, validationResults, failedPrimaryValidation, isValid, onSectionFocus, submitForm, errors } = this.props;
    const { didSubmit } = this.state;

    if (isValidating || !didSubmit) {
      return (
        <div className={classes.spinnerContainer}>
          <Spinner logo />
        </div>
      );
    }

    if (isValid) {
      return (
        <div className={classes.container}>
          <Paper className={classes.card}>
            <Typography variant="h6">No validation errors!</Typography>
          </Paper>
        </div>
      );
    }

    if (!validationResults) {
      return (
        <div className={classes.container}>
          <Paper className={classes.card}>
            <Typography variant="h6" color="error" gutterBottom>
              Failed to validate record
            </Typography>
            <Button onClick={submitForm} variant="contained" color="primary">
              Try Again
            </Button>
          </Paper>
        </div>
      );
    }

    if (failedPrimaryValidation) {
      if (Object.keys(errors).length > 0) {
        return (
          <div className={classes.container}>
            <Paper className={classes.card}>
              <Typography variant="h6" color="error" gutterBottom>
                Unable to submit due to missing information
              </Typography>
              <Typography variant="h6" color="error" gutterBottom>
                Please check that minimum required data is entered
              </Typography>
            </Paper>
          </div>
        );
      }
    }

    const results = validationResults
      .filter((instance) => !instance.result.isValid)
      .map((instance) => ({
        section: SECTIONS.find((section) => section.group === instance.name),
        errors: instance.result.errors,
      }));

    return (
      <div className={classes.container}>
        <div className={classes.cardsContainer}>
          {results.map((resultGroup, i) => (
            <div className={classes.cardContainer} key={i}>
              <ValidationAlertCard
                level={ERROR}
                groupLabel={resultGroup.section.label}
                errors={resultGroup.errors}
                onClick={() => onSectionFocus(resultGroup.section)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidating: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  failedPrimaryValidation: PropTypes.bool.isRequired,
  validationResults: PropTypes.object.isRequired,
  onSectionFocus: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  hideToast: PropTypes.func.isRequired,
  setValidationMode: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { isValidating, validationResults } = state.intake;
  return {
    isValidating,
    validationResults,
  };
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      showToast,
      hideToast,
      setValidationMode,
    },
  ),
)(Summary);
