import { LocalDate } from 'js-joda';
import { boolean, number, string } from 'yup';

export const MIN_LENGTH_ERROR_MESSAGE = 'Too short!';
export const MAX_LENGTH_ERROR_MESSAGE = 'Too long!';
export const REQUIRED_ERROR_MESSAGE = 'Required';

export const NO_SELF_SERVICE_POLICY = '-1';

export const REQUIRED_STRING = string().required(REQUIRED_ERROR_MESSAGE);
export const REQUIRED_NUMBER = number().required(REQUIRED_ERROR_MESSAGE).min(0, REQUIRED_ERROR_MESSAGE);
export const REQUIRED_STRING_NOT_SELF_SERVICE = string().when('selfServicePolicyId', {
  is: (val) => val === NO_SELF_SERVICE_POLICY,
  then: () => REQUIRED_STRING,
});

export const NULLABLE_BOOLEAN = boolean().nullable();
export const NULLABLE_STRING = string().nullable();

export const TODAY = LocalDate.now().atStartOfDay();
export const YEAR_FROM_NOW = TODAY.plusYears(1);
export const FIVE_YEARS_FROM_NOW = TODAY.plusYears(5);
export const YEAR_BEFORE_NOW = TODAY.minusYears(1);
export const FIVE_YEARS_BEFORE_NOW = TODAY.minusYears(5);

export const VALID_START_DATE = LocalDate.parse('1400-01-01').atStartOfDay();
export const VALID_END_DATE = LocalDate.parse('9999-12-31').atStartOfDay();

export const isWithinAYear = (dateStr) => {
  if (!dateStr) {
    return true;
  }

  try {
    const date = LocalDate.parse(dateStr).atStartOfDay();
    return date.compareTo(YEAR_BEFORE_NOW) >= 0 && date.compareTo(YEAR_FROM_NOW) <= 0;
  } catch (err) {
    return false;
  }
};

export const isWithinFiveYears = (dateStr) => {
  if (!dateStr) {
    return true;
  }

  try {
    const date = LocalDate.parse(dateStr).atStartOfDay();
    return date.compareTo(FIVE_YEARS_BEFORE_NOW) >= 0 && date.compareTo(FIVE_YEARS_FROM_NOW) <= 0;
  } catch (err) {
    return false;
  }
};

export const recentDateSchema = string().test(
  'is-recent',
  'Outside the expected date range',
  isWithinAYear,
);

export const plusMinusFiveYearsDateSchema = string().test(
  'is-recent',
  'Outside the expected date range',
  isWithinFiveYears,
);

export const isWithinValidDateRange = (dateStr) => {
  if (!dateStr) {
    return true;
  }

  try {
    const date = LocalDate.parse(dateStr).atStartOfDay();
    return date.compareTo(VALID_START_DATE) >= 0 && date.compareTo(VALID_END_DATE) <= 0;
  } catch (err) {
    return false;
  }
};

export const validDateRangeSchema = string().test(
  'is-recent',
  'Outside the expected date range',
  isWithinValidDateRange,
);

export const NOTE_MAX_LENGTH = 120;

export const INTEGER_MAX = 2147483647; // 4 byte max