import { boolean, object, string } from 'yup';

import { formatForWire, formatZonedDateForInput, isNotDefined, isType, parseNumbers } from 'utilities/common';

import { 
  MAX_LENGTH_ERROR_MESSAGE, 
  NO_SELF_SERVICE_POLICY,
  NULLABLE_STRING,
  REQUIRED_STRING,
  REQUIRED_STRING_NOT_SELF_SERVICE,
  plusMinusFiveYearsDateSchema,
} from 'modules/common/constants';

export const isCandidateMoveType = (moveTypeId, moveTypeMetadata) => {
  return isType('CANDIDATE', moveTypeId, moveTypeMetadata);
};

export const isInternMoveType = (moveTypeId, moveTypeMetadata) => {
  return isType('INTERN', moveTypeId, moveTypeMetadata);
};


export default class AuthorizationInformation {
  static schema = object().shape({
    mtAuthorizationId: string().max(20, MAX_LENGTH_ERROR_MESSAGE),
    client: object(),
    moveTypeId: REQUIRED_STRING_NOT_SELF_SERVICE,
    authorizationDate: REQUIRED_STRING_NOT_SELF_SERVICE,
    vip: boolean(),
    transfereeHasWebAccess: boolean(),
    reloType: string(),
    newJobTitle: string().max(80, MAX_LENGTH_ERROR_MESSAGE),
    repaymentAgreementRequired: boolean(),
    repaymentAgreementSentDate: plusMinusFiveYearsDateSchema,
    repaymentAgreementReceivedDate: plusMinusFiveYearsDateSchema,
    selfServicePolicyId: REQUIRED_STRING,
    selfServicePolicyBudget: NULLABLE_STRING,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.mtAuthorizationId = json.mtAuthorizationId;
    this.client = json.client;
    this.moveTypeId = isNotDefined(json.moveTypeId) ? '' : json.moveTypeId;
    this.authorizationDate = formatZonedDateForInput(json.authorizationDate);
    this.vip = json.vip;
    this.transfereeHasWebAccess = json.transfereeHasWebAccess;
    this.newJobTitle = json.newJobTitle || '';
    this.repaymentAgreementRequired = json.repaymentAgreementRequired;
    this.repaymentAgreementSentDate = formatZonedDateForInput(json.repaymentAgreementSentDate);
    this.repaymentAgreementReceivedDate = formatZonedDateForInput(json.repaymentAgreementReceivedDate);
    this.selfServicePolicyId = json.selfServicePolicyId || NO_SELF_SERVICE_POLICY;
    this.selfServicePolicyBudget = json.selfServicePolicyBudget;
  }

  static fromWireData(wireData) {
    return new AuthorizationInformation(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      mtAuthorizationId: this.mtAuthorizationId,
      client: this.client,
      moveTypeId: this.moveTypeId,
      authorizationDate: this.authorizationDate,
      vip: this.vip,
      transfereeHasWebAccess: this.transfereeHasWebAccess,
      newJobTitle: this.newJobTitle,
      repaymentAgreementRequired: this.repaymentAgreementRequired,
      repaymentAgreementSentDate: this.repaymentAgreementSentDate,
      repaymentAgreementReceivedDate: this.repaymentAgreementReceivedDate,
      selfServicePolicyId: this.selfServicePolicyId,
      ...parseNumbers({
        selfServicePolicyBudget: this.selfServicePolicyBudget,
      }),
    });
  }
}
