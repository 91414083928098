import { Fab, Grid, Paper, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { values as getValues } from 'lodash';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { showToast } from 'modules/layout/layout.actions';

import { ADD_COMMENT_FAILURE } from 'modules/intake/types';
import { AuthorizationComment } from 'modules/intake/models';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { addCommentToRecord } from 'modules/intake/intake.actions';
import AddCommentDialog from './addCommentDialog.component';
import AuthorizationCommentView from './authorizationComment.component';

const styles = (theme) => ({
  noCommentsContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  commentButtonContainer: {
    position: 'fixed',
    right: theme.spacing(0.5),
    top: '50%',
  },
});

class AuthorizationCommentsForm extends PureComponent {
  state = {
    isAddingComment: false,
    isSubmitting: false,
  };

  handleCommentAdded = async (comment) => {
    const {
      values: { id, authorizationComments },
      metadata: { commentServiceTypes, commentRegardingTypes },
    } = this.props;
    this.setState({ isSubmitting: true });

    const commentWireData = comment.toWireData();
    const { type } = await this.props.addCommentToRecord(commentWireData, { intakeRecordId: id });
    if (type === ADD_COMMENT_FAILURE) {
      this.props.showToast('Failed to save comment, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      this.props.setFieldValue('authorizationComments', [
        ...authorizationComments,
        new AuthorizationComment(comment, commentServiceTypes, commentRegardingTypes),
      ]);
      this.setState({ isAddingComment: false });
    }
    this.setState({ isSubmitting: false });
  };

  render() {
    const {
      classes,
      values: { authorizationComments },
      theme,
      metadata: { commentServiceTypes, commentRegardingTypes },
    } = this.props;
    const { isAddingComment, isSubmitting } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          {authorizationComments.map((comment, i) => (
            <Grid key={i} item xs={12}>
              <div className="mb-1">
                <AuthorizationCommentView comment={comment} />
              </div>
            </Grid>
          ))}
        </Grid>

        {authorizationComments.length === 0 &&
          <Paper className={classes.noCommentsContainer}>
            <Typography variant="h3">No Comments</Typography>
          </Paper>
        }

        <div className={classes.commentButtonContainer}>
          <Fab color="primary" onClick={() => this.setState({ isAddingComment: true })}>
            <FontAwesomeIcon size="lg" icon={['far', 'plus']} color={theme.palette.primary.contrastText} />
          </Fab>
        </div>

        {isAddingComment && // forces comment to clear each time, allows for retrying save if it fails
          <AddCommentDialog
            open={true}
            onDiscard={() => this.setState({ isAddingComment: false })}
            onSubmit={this.handleCommentAdded}
            serviceTypes={getValues(commentServiceTypes)}
            regardingTypes={getValues(commentRegardingTypes)}
            disabled={isSubmitting}
          />
        }
      </>
    );
  }
}

AuthorizationCommentsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  addCommentToRecord: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(null, {
    addCommentToRecord,
    showToast,
  }),
)(AuthorizationCommentsForm);
